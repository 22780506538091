.mainWrapper{
    width: 100%;
    height: 100svh;
    background: #FAFAFA;
    overflow-y: hidden;
}
.mainWrapperInvalidKey{
    width: 100%;
    height: 100svh;
    background: #FAFAFA;
    overflow-y: hidden;
    background-image: url("https://res.cloudinary.com/iamironman/image/upload/v1665201680/web-icons/SharpBaggyIchthyostega-max-1mb_vniryg.gif");
    color: white;
}
.mainWrapperContent{
    height: 100svh;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contactUsBtn{
    padding: 20px;
}
.contactUsBtn a{
    background-color: white;
    color: black;
    padding: 10px 28px;
    font-weight: 700;
    text-decoration: none;
    /* border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px; */
}
.textContainer{
    width: 80%;
    padding: 40px 10%;
}
.whoops{
    font-size: 35px;
    font-weight: 700;
    text-align: left;
    padding: 20px 0px;
}
.notAValidPage{
    font-size: 26px;
    font-weight: 700;
    color: white;
    opacity: 85%;
}

.successfullySubmitted{
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100svh;
}
.successfullySubmitted p{
    font-size: 20px;
    color: green;
    font-weight: 500;
    padding: 0px 20%;
    text-align: center;
}
.successfullySubmitted img{
    width: 80px;
    height: 80px;
}


.mainLoader{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100svh;
    background-color: #dddddde8;
    z-index: 10000;
    flex-direction: column;
    justify-content: center;
    display: inline-flex;
    align-items: center;
}
.mainLoader img{
    width: 160px;
    height: 160px;
}
.mainLoader p{
    color: black;
    font-weight: 600;
}

.mainHeaderWrapper{
    width: 100%;
    height: 7svh;
    /* box-shadow:  0 4px 8px 0 rgb(0 0 0 / 78%), 0 6px 20px 0 rgb(0 0 0 / 50%); */
    border-bottom: 1px solid #dfdfdf7d;
    background-color: white;
}
.mainHeaderContainer{
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}
.headerTitle{
    padding: 0px 5%;
    font-weight: 700;
}
.mainHeaderSubCaption{
    font-size: 11px;
    font-weight: 400;
    color: #a5a5a5;
}
.mainBodyWrapper{
    margin: 1.5vh 0px;
    height: 80.5svh;
    background: white;
    border-bottom: 1px solid #dfdfdf7d;
    border-top: 1px solid #dfdfdf7d;
    overflow-y: scroll;
}
.mainBodyContainer {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}
.initiateContainer{
    width: 90%;
    padding: 0px 5%;
}
.initiateTitle{
    font-size: 20px;
    font-weight: 600;
}
.initiateSubTitle{
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0px;
    color: #a5a5a5;
}
.eachStar{
    padding: 0px 8px 0px 0px;
}
.starContainer{
    padding: 20px 0px;
}


.furtherContainer{
    border-top: 1px solid #dfdfdf;
}

.whatWentWrongContainer{
    width: 90%;
    padding: 20px 5%;
}
.wwwcMainTitle{
    font-weight: 700;
    font-size: 18px;
}
.eachWwwcContainer{
    width: 100%;
    padding: 14px 0px;
}
.eachWwwcHeader{
    display: inline-flex;
    flex-direction: column;
}
.eachWwwcHeaderSelected{
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
}
.eachWwwcTitle{
    font-weight: 600;
    color: #00000086;
}




.careToShareMoreContainer{
    border-top: 1px solid #dfdfdf;
    width: 90%;
    padding: 20px 5%;
}
.careToShareMoreTitle{
    font-weight: 600;
}
.careToShareMoreContainer textarea{
    border: 1px solid #dfdfdf;
    padding: 8px 3%;
    width: calc(94% - 2px);
    margin: 8px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    max-height: 400px;
    min-height: 120px;
    resize: none;
    background-color: white;
}
.careToShareMoreContainer textarea::placeholder{
    color: #dfdfdf;
}


.wcbiOptions{
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
}
.eachWcbiContainer{
    padding: 8px 14px;
    border: 1px solid #dfdfdf;
    margin: 6px 6px 0px 0px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    font-size: 12px;
    background-color: #dfdfdf;
}
.eachWcbiContainerSelected{
    padding: 8px 14px;
    border: 1px solid white;
    margin: 6px 6px 0px 0px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    font-size: 12px;
    background-color: red;
    color: white;
}



.mainFooterWrapper{
    height: 10svh;
    border-top: 1px solid #dfdfdf7d;
    background-color: white;
    /* box-shadow:  0px 20px 25px 0px rgb(0 0 0 / 7%), 0 6px 20px 0 rgb(0 0 0 / 26%); */
}
.mainFooterContainer{
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}
.submitBtnDisabled{
    border: none;
    width: 90%;
    margin-bottom: 20px;
    padding: 12px 0px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.3px;
    color: #a5a5a5;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.submitBtnEnabled{
    border: none;
    width: 90%;
    margin-bottom: 20px;
    padding: 12px 0px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.3px;
    background-color: black;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}